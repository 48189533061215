import React from 'react';
import PropTypes from 'prop-types';

import TripUpdatesRouteDayTableEntries from './trip-updates-route-day-table-entries';

/*destructure props object*/
export default function TripUpdatesRouteDayTable ({array, title, date}){

    if ( array !== undefined && array !== null) {
	/*return a React element*/
	return (
            <>
		<p>Table of {array.length}&nbsp;{title} for {date}:</p>
		<table>
		<thead>
		    <tr>
			<th>agency_name&nbsp;|</th>
			<th>agency_id&nbsp;|</th>
			<th>route_id&nbsp;|</th>
			<th>route_short_name&nbsp;|</th>
			<th>trip_id&nbsp;|</th>
			<th>trip_short_name&nbsp;|</th>
			<th>trip_headsign&nbsp;|</th>
			<th>timestamp_pgsql&nbsp;|</th>
		    </tr>
		</thead>
		<tbody>
		    <TripUpdatesRouteDayTableEntries array={array} />
		</tbody>
		</table>
            </>
	);
    }else{
	return (
            <>
		<p>loading...</p>
            </>
	);
    }
};
TripUpdatesRouteDayTable.propTypes = {
    array: PropTypes.array,
    title: PropTypes.string,
    date: PropTypes.string
};
