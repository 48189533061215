import React from 'react';

import packageInfo from '../../package.json'
const VERSION = packageInfo.version;

export default function Contact() {
    return (
        <>
            <p>
		For questions about this website please do not hesitate to reach out to dialog (at) swingbe (dot) de.
            </p>
            <p>
		Please feel <b>free</b> to <b>use</b>, <b>study</b>, <b>share</b> or <b>improve</b> the{' '}
                <a
                    href="https://git.wtf-eg.de/dancingCycle/gtfs-display"
                    target="_blank"
                >
		    source
                </a>
		.
            </p>
	    <p>
		Version: {VERSION}
	    </p>
        </>
    );
};
