import React from 'react';

const StopTimesTableHead = () => {
    return (
        <tr>
            <th>trip_id</th>
            <th>arrival_time_hours</th>
            <th>arrival_time_minutes</th>
            <th>departure_time_hours</th>
            <th>departure_time_minutes</th>
            <th>stop_id</th>
            <th>stop_sequence</th>
            <th>pickup_type</th>
            <th>drop_off_type</th>
            <th>stop_headsign</th>
        </tr>
    );
};

export default StopTimesTableHead;
