import React, { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../config';
import TripUpdatesRouteDayTable from '../components/trip-updates-route-day-table';
import AgencySelect from '../components/agency-select';
import RouteSelect from '../components/route-select';
import Input from '../components/input';

export default function TripUpdates() {

    const dateDefault = 'Select date';
    const [date, setDate] = useState(dateDefault);

    const agencyNameDefault = 'Select GTFS agency_name';
    const routeNameDefault = 'Select GTFS route_short_name';

    /*store and initialize data in function component state*/
    const [strngAgencyId, setStrngAgencyId] = useState(agencyNameDefault);
    const [strngRouteId, setStrngRouteId] = useState(routeNameDefault);

    const [rryTripUpdates, setRryTripUpdates] = useState([]);
    const [rryAgencies, setRryAgencies] = useState([]);
    const [rryRoutes, setRryRoutes] = useState([]);

    //TODO How do we handle invalid date input?
    const handleDate = (e) => {
	if (e.target.value.indexOf('2023') !== -1 ||
	   e.target.value.indexOf('2024') !== -1) {
            setDate((date)=>e.target.value);
	}
    };

    const getRryAgencies = async () => {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    const address = `${config.API}agencyids`;
	    //console.log('trip-updates-route-day res.data.length: address: ' + address);
            const res = await axios.get(address);
	    //console.log('trip-updates-route-day res.data.length: Agencies: ' + res.data.length);
            setRryAgencies((rryAgency) => res.data);
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
    };

    const getRryRoutes = async () => {
	if ( strngAgencyId !== agencyNameDefault ) {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    const address = `${config.API}routes?agencyid=${strngAgencyId}`;
	    //console.log('trip-updates-route-day res.data.length: address: ' + address);
            const res = await axios.get(address);
	    if ( res.data !== undefined && res.data !== null ) {
		//console.log('trip-updates-route-day res.data.length: Routes: ' + res.data.length);
		setRryRoutes((rryRoutes) => res.data);
		if ( res.data.length > 0 ) {
	            setStrngRouteId((strngRouteId) => res.data[0].route_id);
		} else {
		    console.error('ERROR: agency has NO routes');
		}
	    } else {
		console.error('ERROR: routes by agency request FAILED');
	    }

        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	}	    
    };

    const getRryTripUpdates = async () => {
	if ( strngRouteId !== routeNameDefault &&
	   date !== dateDefault) {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    //console.log('route: ' + strngRouteId);
	    const address = `${config.API}trip-updates-by-route-day?routeid=${strngRouteId}&day=${date}`;
	    //console.log('trip-updates-route-day res.data.length: address: ' + address);
            const res = await axios.get(address);
	    if ( res.data !== undefined && res.data !== null ) {
		//console.log('trip-updates-route-day res.data.length: TripUpdates: ' + res.data.length);
		setRryTripUpdates((rryTripUpdates) => res.data);
	    } else {
		console.error('ERROR: trip-updates by routes and day request FAILED');
	    }
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	}
    };

    const handleChangeAgencyId = (event) => {
	//console.log('trip-updates-route-day: handleChangeAgencyId() value: ' + event.target.value);
        setStrngAgencyId((strngAgencyId) => event.target.value);
    };

    const handleChangeRouteId = (event) => {
	//console.log('trip-updates-route-day: handleChangeRouteId() value: ' + event.target.value);
        setStrngRouteId((strngRouteId) => event.target.value);
    };

    /*this hook is run after a DOM update. Changing state might result in an infinite loop*/
    /*hook need to be placed in body of the function component in which it is used*/
    useEffect(() => {
        getRryAgencies();
    /*use an empty dependency array to ensure the hook is running only once*/
    /*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, []);

    useEffect(() => {
	//console.log('trip-updates-route-day: useEffect() strngAgencyId: ' + strngAgencyId);
        getRryRoutes();
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [strngAgencyId]);

    useEffect(() => {
	//console.log('trip-updates-route-day: useEffect() strngRouteId: ' + strngRouteId);
        getRryTripUpdates();
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [strngRouteId, date]);


    //TODO get rry based on route_id!
    return <>
                <label>
		    <Input
			id="inputDate"
			name={dateDefault}
			onChange={handleDate}
			placeholder="Enter date ${dateDefault}"
			type="date"
			title="Enter date ${dateDefault}"
			value={date}
		    />
		    </label>
		   <AgencySelect rry={rryAgencies} name={agencyNameDefault} onChange={handleChangeAgencyId} />
		   <RouteSelect rry={rryRoutes} name={routeNameDefault} onChange={handleChangeRouteId} />
	       <TripUpdatesRouteDayTable array={rryTripUpdates} title={'trips'} date={date}/>
	   </>;
};
