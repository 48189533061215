import React from 'react';
import PropTypes from 'prop-types';

import Count from './overview-next-table-count';

/*destructure props object*/
export default function OverviewNextTableEntry ({ agencyName, routeCount, stopCount, tripCount }) {
    const routeCountBadge = <Count count={routeCount} />;
    const stopCountBadge = <Count count={stopCount} />;
    const tripCountBadge = <Count count={tripCount} />;
    return (
        <tr>
            <td>{agencyName}</td>
            <td>{routeCountBadge}</td>
            <td>{stopCountBadge}</td>
            <td>{tripCountBadge}</td>
        </tr>
    );
};

OverviewNextTableEntry.propTypes = {
    agencyName: PropTypes.string,
    routeCount: PropTypes.string,
    stopCount: PropTypes.string,
    tripCount: PropTypes.string
};
