import React from 'react';

const RoutesTableHead = () => {
    return (
        <tr>
            <th>route_id</th>
            <th>agency_id</th>
            <th>route_short_name</th>
            <th>route_long_name</th>
            <th>route_type</th>
            <th>route_color</th>
            <th>route_text_color</th>
            <th>route_desc</th>
        </tr>
    );
};

export default RoutesTableHead;
