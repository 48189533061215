import React from 'react';

const ShapesTableHead = () => {
    return (
        <tr>
            <th>shape_id</th>
            <th>shape_pt_lat</th>
            <th>shape_pt_lon</th>
            <th>shape_pt_sequence</th>
        </tr>
    );
};

export default ShapesTableHead;
