import React, { useState } from 'react';

import RadioButton from '../components/radio-button';
import Rltm from '../components/realtime';

export default function Realtime() {
    const [state, setState] = useState('');

    const handleChange = (e) => {
	setState(e.target.value);
    }
    return (
	<>
	    <RadioButton state={state} onChange={handleChange}/>
	    <br />
	    <Rltm state={state} />
	</>
    );

};
