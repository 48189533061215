import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import GtfsFile from './gtfs-file';
import gtfs from '../utils/gtfs';

export default function GtfsFiles() {
    //TODO Do we have to call an API for each and every dataset file?
    return <fieldset>
               <legend><b>GTFS Schedule</b> feed overview (file: item count)</legend>
	       <ul>
		   {gtfs.datasetFiles.map((item, index) => {
		       return <GtfsFile key={index} name={item} />;
		   })}
	       </ul>
           </fieldset>;
};

GtfsFiles.propTypes = {
    data: PropTypes.array
};
