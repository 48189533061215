import React from 'react';

const CalendarTableHead = () => {
    return (
        <tr>
            <th>service_id</th>
            <th>monday</th>
            <th>tuesday</th>
            <th>wednesday</th>
            <th>thursday</th>
            <th>friday</th>
            <th>saturday</th>
            <th>sunday</th>
            <th>start_date</th>
            <th>end_date</th>
        </tr>
    );
};

export default CalendarTableHead;
