import React, { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../config';
import PerDayTable from '../components/per-day-table';
import Input from '../components/input';

export default function PerDay() {

    const dateDefault = 'Select date';
    const [date, setDate] = useState(dateDefault);

    const [rryPerDay, setRryPerDay] = useState([]);
    const [rryAgencies, setRryAgencies] = useState([]);

    //TODO How do we handle invalid date input?
    const handleDate = (e) => {
	if (e.target.value.indexOf('2023') !== -1 ||
	   e.target.value.indexOf('2024') !== -1) {
            setDate((date)=>e.target.value);
	}
    };

    const fetchData = async () => {
	if ( date !== dateDefault) {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    const address = `${config.API}trip-updates-by-day?day=${date}`;
	    //console.log('address: ' + address);
            const res = await axios.get(address);
	    if ( res.data !== undefined && res.data !== null ) {
		//console.log('res.data.length: ' + res.data.length);
		setRryPerDay((rryPerDay) => res.data);
	    } else {
		console.error('ERROR: trip-updates by routes and day request FAILED');
	    }
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	}
    };

    /*this hook is run after a DOM update. Changing state might result in an infinite loop*/
    /*hook need to be placed in body of the function component in which it is used*/
    useEffect(() => {
        fetchData();
    /*use an empty dependency array to ensure the hook is running only once*/
    /*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [date]);

    return <>
                <label>
		    <Input
			id="inputDate"
			name={dateDefault}
			onChange={handleDate}
			placeholder="Enter date ${dateDefault}"
			type="date"
			title="Enter date ${dateDefault}"
			value={date}
		    />
		    </label>
	       <PerDayTable array={rryPerDay} title={'feeds'} date={date}/>
	   </>;
};
