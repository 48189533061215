import React from 'react';

const TripsTableHead = () => {
    return (
        <tr>
            <th>route_id</th>
            <th>service_id</th>
            <th>trip_id</th>
            <th>trip_headsign</th>
            <th>trip_short_name</th>
            <th>direction_id</th>
            <th>block_id</th>
            <th>shape_id</th>
            <th>wheelchair_accessible</th>
            <th>bikes_allowed</th>
        </tr>
    );
};

export default TripsTableHead;
