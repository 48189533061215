import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param rry Array of agency objects containing id and name
 */
export default function AgencySelect({ name, onChange, rry }) {
    
    if (rry !== undefined && rry !== null && rry.length > 0) {
        return <form >
			<label htmlFor="input-agency">{name}: </label>
			<select
			    name={name}
			    id={name}
			    className={name}
			    onChange={onChange}
			    placeholder={name}
			    defaultValue={name}
			    title={name}
			    type="text"
			    required
			>
			    {rry.map((item) => (
				<option key={item.agency_id} value={item.agency_id}>
				    {item.agency_name}
				</option>
			    ))}
			</select>
	       </form>;
    } else {
        return <p>Loading...</p>;
    }
};

AgencySelect.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    rry: PropTypes.array
};
