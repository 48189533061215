import React from 'react';
import PropTypes from 'prop-types';

export default function TripUpdatesRouteDayTableEntries ({array}) {
    if ( array !== undefined && array !== null && array.length > 0 ) {

	//TODO Shall we switch from UTC to local time zone for item.timestamp_pgsql?
        //iterate over array
        return array.map((item, index) => {
            return (
		<tr
		    key={index}
		>
		    <td>{item.agency_name}&nbsp;|</td>
		    <td>{item.agency_id}&nbsp;|</td>
		    <td>{item.route_id}&nbsp;|</td>
		    <td>{item.route_short_name}&nbsp;|</td>
		    <td>{item.trip_id}&nbsp;|</td>
		    <td>{item.trip_short_name}&nbsp;|</td>
		    <td>{item.trip_headsign}&nbsp;|</td>
		    <td>{item.timestamp_pgsql}&nbsp;|</td>
		</tr>
	    );
        });
    }else{
	//data is empty
	return null;
    }
};

TripUpdatesRouteDayTableEntries.propTypes = {
    array: PropTypes.array
};
