import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AgencyPerDay from '../pages/agency-per-day';
import GroupAgencyPerDay from '../pages/group-agency-per-day';
import OddRoutes from './odd-routes';
import OddTrips from './odd-trips';
import PerDay from '../pages/per-day';
import TripUpdates from '../pages/trip-updates-route-day';

export default function Realtime({ state }) {
    if ( state === 'odd-routes' ) {
	return (
	    <>
		<p>
		    Show <b>GTFS Realtime TripUpdate</b> entities that do <b>not</b> match any <b>GTFS Schedule routes</b>:
		</p>
		<OddRoutes />
	    </>
	);
    } else if ( state === 'odd-trips' ) {
	return (
	    <>
		<p>
		    Show <b>GTFS Realtime TripUpdate</b> entities that do <b>not</b> match any <b>GTFS Schedule trips</b>:
		</p>
		<OddTrips />
	    </>
	       );
    } else if ( state === 'feed' ) {
	return (
	    <>
		<p>
		    Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>feed</b> level:
		</p>
		<PerDay />
	    </>
	);
    }
    else if ( state === 'agencies' ) {
    return (
	<>
	    <p>
		Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>agencies</b> level:
	    </p>
	    <GroupAgencyPerDay />
	</>
    );
    } else if ( state === 'routes' ) {
    return (
	<>
	    <p>
		Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>routes</b> level:
	    </p>
	    <AgencyPerDay />
	</>
    );
    } else if ( state === 'trips' ) {
    return (
	<>
	    <p>
		Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>trips</b> level:
	    </p>
            <TripUpdates />
	</>
    );
    } else {
	return <p>Select a <b>GTFS Realtime</b> analysis rule to contine!</p>;
    }	
};


Realtime.propTypes = {
    state: PropTypes.string
};
