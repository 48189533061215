import React, { useState, useEffect } from 'react';
import axios from 'axios';

import config from '../config';
import Input from '../components/input';

export default function OddTrips() {

    const dateDefault = 'Select date';
    const [date, setDate] = useState(dateDefault);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items to display per page

    //TODO How do we handle invalid date input?
    const handleDate = (e) => {
	if (e.target.value.indexOf('2023') !== -1 ||
	   e.target.value.indexOf('2024') !== -1) {
            setDate((date) => e.target.value);
	}
    };

    /*this hook is run after a DOM update. Changing state might result in an infinite loop*/
    /*hook need to be placed in body of the function component in which it is used*/
    useEffect(() => {
	fetchData();
    /*use an empty dependency array to ensure the hook is running only once*/
    /*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [date]);

    const fetchData = async () => {
	if ( date !== dateDefault) {
	try {
	    const address = `${config.API}trip-updates-odd-routes?day=${date}`;
	    //console.log('address: ' + address);
	    const res = await axios.get(address);
	    if ( res.data !== undefined && res.data !== null ) {
		//console.log('res.data.length: ' + res.data.length);
		setData((data) => res.data);
	    } else {
		console.error('ERROR: trip-updates with odd routes request FAILED');
	    }
	} catch (err) {
	    console.log(err);
	}
	}
    };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
      <>
                <label>
		    <Input
			id="inputDate"
			name={dateDefault}
			onChange={handleDate}
			placeholder="Enter date ${dateDefault}"
			type="date"
			title="Enter date ${dateDefault}"
			value={date}
		    />
		    </label>
	  <p>
	      abs odd route count: {data.length}, total pages: {Math.ceil(data.length / itemsPerPage)}, current page: {currentPage}
	  </p>
	  <dl>
          {currentItems.map((item, index) => (
              <dt key={index}>{item.trip_route_id}</dt>
        ))}
	  </dl>
	  <button onClick={handlePreviousPage}>Previous</button>
	  <button onClick={handleNextPage}>Next</button>
    </>
  );
};
