import React from 'react';

const FrequenciesTableHead = () => {
    return (
        <tr>
            <th>trip_id</th>
            <th>start_time</th>
            <th>end_time</th>
            <th>headway_secs</th>
            <th>exact_times</th>
        </tr>
    );
};

export default FrequenciesTableHead;
