import React from 'react';
import PropTypes from 'prop-types';

export default function PerDayTableEntries ({array}) {
    if ( array !== undefined && array !== null && array.length > 0 ) {
	//TODO Shall we switch from UTC to local time zone for item.timestamp_pgsql?
        //iterate over array
        return array.map((item, index) => {
            return (
		<tr
		    key={index}
		>
		    <td>{item.count}&nbsp;|</td>
		    <td>{item.rt_part}&nbsp;|</td>
		    <td>{item.count === 0 ? 0 : ((item.rt_part / item.count) * 100).toFixed(2)}&nbsp;|</td>
		    <td>{item.timestamp_pgsql}&nbsp;|</td>
		</tr>
	    );
        });
    }else{
	//data is empty
	return null;
    }
};

PerDayTableEntries.propTypes = {
    array: PropTypes.array
};
