import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param rry Array of route objects containing id and name
 */
export default function RouteSelect({ name, onChange, rry }) {
    
    if (rry !== undefined && rry !== null) {
        return (<>
		    <form >
			<label htmlFor="input-route">{name}: </label>
			<select
			    name={name}
			    id={name}
			    className={name}
			    onChange={onChange}
			    placeholder={name}
			    defaultValue={name}
			    title={name}
			    type="text"
			    required
			>
			    {rry.map((item) => (
				<option key={item.route_id} value={item.route_id}>
				    {item.route_short_name} 
				</option>
			    ))}
			</select>
		    </form>
		</>);
    } else {
        return <p>Loading...</p>;
    }
};

RouteSelect.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    rry: PropTypes.array
};
