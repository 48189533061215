import React from 'react';

const PathwaysTableHead = () => {
    return (
        <tr>
            <th>pathway_id</th>
            <th>from_stop_id</th>
            <th>to_stop_id</th>
            <th>pathway_mode</th>
            <th>is_bidirectional</th>
            <th>length</th>
            <th>traversal_time</th>
            <th>stair_count</th>
            <th>max_slope</th>
            <th>min_width</th>
            <th>signposted_as</th>
            <th>reversed_signposted_as</th>
        </tr>
    );
};

export default PathwaysTableHead;
