import React from 'react';

export default function OverviewNextTableHead () {
    return (
        <tr>
            <th>Agency&nbsp;|</th>
            <th>Route Count&nbsp;|</th>
	    <th>Stop Count&nbsp;|</th>
            <th>Trip Count&nbsp;|</th>
        </tr>
    );
};
