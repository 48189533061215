 import React from 'react';

const LevelsTableHead = () => {
    return (
        <tr>
            <th>level_id</th>
            <th>level_index</th>
        </tr>
    );
};

export default LevelsTableHead;
