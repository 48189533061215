import React, { useEffect, useState } from 'react';
import axios from 'axios';

import OverviewTable from '../components/overview-next-table';
import config from '../config';

export default function OverviewNext() {

    const [cnts, setCnts] = useState([]);

    const getCnts = async () => {
        try {
            /*get cnts*/
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
            const res = await axios.get(`${config.API}rts-stps-trps-cnt`);

	    if ( res !== null && res !== undefined ) {
		setCnts(res.data);
	    }

        } catch (err) {
            console.error('err.message: ' + err.message);
        }
    };

    useEffect(() => {
            getCnts();
    }, []);


    if ( cnts === null || cnts === undefined || cnts.length === 0 ) {
	return <p>loading...</p>;
    } else {
	return <OverviewTable overview={cnts} />;
    }
};
