import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FileSelect from '../components/file-select';
import TablePage from '../components/table-page';
import gtfs from '../utils/gtfs';

export default function FileSelection({ options }) {

    /*store and initialize data in function component state*/
    const [fileName, setFileName] = useState(gtfs.datasetFiles[0]);
    const handleChangeFile = (event) => {
        setFileName((fileName) => event.target.value);
    };
    if (options.length > 0) {
        return <>
                <FileSelect
                    name="file"
                    onChange={handleChangeFile}
                    options={options}
		    title='Select GTFS dataset file'
                />
                <TablePage name={fileName} />
               </>;
    } else {
        return <p>Loading...</p>;
    }
};

FileSelection.propTypes = {
    options: PropTypes.array
};
