import React from 'react';
import PropTypes from 'prop-types';

import Entry from './overview-next-table-entry';
import Head from './overview-next-table-head';

/*the simplest way to define a component is to write a JavaScript function*/
/*destructure props object*/
export default function OverviewNextTable ({ overview }) {
    const handleOverview = () => {

	if ( overview !== null && overview !== undefined && overview.length !== 0 ) {
            //iterate over array
            return overview.map((item, index) => {
                return (
                    <Entry
                        agencyName={item.agency_name}
                        routeCount={item.rts_cnt}
			stopCount={item.stps_cnt}
                        tripCount={item.trps_cnt}
			key={item.agency_id}
                    />
                );
            });
        } else {
            console.error('overview NOT available');
            return null;
        }
    };

    /*return a React element*/
    return <fieldset>
            <legend><b>GTFS Schedule</b> agency overview</legend>
            {/*size="sm" cuts cell padding in half*/}
            {/*variant="dark" inverts colors*/}
            <table>
                <thead>
                    <Head />
                </thead>
                <tbody>{handleOverview()}</tbody>
            </table>
	</fieldset>;
};

OverviewNextTable.propTypes = {
    overview: PropTypes.array
};
