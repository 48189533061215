import React from 'react';
import PropTypes from 'prop-types';

export default function CountNext({ count }) {
    if (count === '0'
	|| count === null
	|| count === undefined) {
	return '0';
    } else {
        return count;
    }
};

CountNext.propTypes = {
    count: PropTypes.string
};
