import React from 'react';
import PropTypes from 'prop-types';

import PerDayTableEntries from './per-day-table-entries';

/*destructure props object*/
export default function PerDayTable ({array, title, date}){

    if ( array !== undefined && array !== null) {
	/*return a React element*/
	return (
            <>
		<p>Table of {array.length}&nbsp;{title} for {date}:</p>
		<table>
		<thead>
		    <tr>
			<th>abs. trip count&nbsp;|</th>
			<th>RT TU count&nbsp;|</th>
			<th>RT TU %&nbsp;|</th>
			<th>latest RT timestamp&nbsp;|</th>
		    </tr>
		</thead>
		<tbody>
		    <PerDayTableEntries array={array} />
		</tbody>
		</table>
            </>
	);
    }else{
	return (
            <>
		<p>loading...</p>
            </>
	);
    }
};
PerDayTable.propTypes = {
    array: PropTypes.array,
    title: PropTypes.string,
    date: PropTypes.string
};
