import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/header';
import Home from './pages/homepage';
import Files from './pages/files';
//TODO Date [today] is not implemented! import Overview from './pages/overview';
import OverviewNext from './pages/overview-next';
//TODO Disable this route as it is not working! import Service from './pages/service';
//TODO Disable this route as it is way too much overhead for API and database! import TripCalendar from './pages/trip-calendar';
import Realtime from './pages/realtime';
//TODO import Trips from './pages/trips-route-day';
import Contact from './pages/contact';

import packageInfo from '../package.json'
const VERSION = packageInfo.version;

export default function Main() {
    return (
    //Router is the router implementation for HTML5 browsers
    //Link enables Routes on an anchor tag
    //Switch returns only the first matching route rather than all
    //Route is the conditionally shown component //based on matching a path to a URL
        <Router>
	    <Header />
		<h1>GTFS-Display</h1>
		<p>
		    This website processes GTFS Realtime and Schedule data.
		</p>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/agency" element={<OverviewNext />} />
                <Route exact path="/files" element={<Files />} />
                <Route exact path="/realtime" element={<Realtime />} />
                <Route exact path="/contact" element={<Contact />} />
		<Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
    /** TODO Is this route of any value?
                <Route exact path="/trips" element={<Trips />} />
    */
    /** TODO Date [today] is not implemented!
                <Route exact path="/overview-today" element={<Overview />} />
    */

    /** TODO Disable this route as it is not working!
                <Route exact path="/service" element={<Service />} />
    */

    /** TODO Disable this route as it is way too much overhead for API and database!
                <Route exact path="/trip-calendar" element={<TripCalendar />} />
    */
};
