import React from 'react';
import PropTypes from 'prop-types';

/*controlled component: input form value controlled by React*/
export default function Input({id, name, onChange, placeholder, title, type, value}) {
    return <form >
	       <label htmlFor="input-agency">{name}: </label>
	       <input
		   name={name}
		   id={id}
		   className={name}
		   onChange={onChange}
		   placeholder={placeholder}
		   title={title}
		   type={type}
		   value={value}
		   required
	       />
	   </form>;
};

Input.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func
};
