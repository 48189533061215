import React from 'react';
import PropTypes from 'prop-types';

import GroupAgencyPerDayTableEntries from './group-agency-per-day-table-entries';

//TODO For the cnnct feed, why do we get a 93 length agency array instead of 123?

/*destructure props object*/
export default function GroupAgencyPerDayTable ({array, title, date}){

    if ( array !== undefined && array !== null) {
	/*return a React element*/
	return (
            <>
		<p>Table of {array.length}&nbsp;{title} for {date}:</p>
		<table>
		<thead>
		    <tr>
			<th>agency_name&nbsp;|</th>
			<th>abs. trip count&nbsp;|</th>
			<th>RT TU count&nbsp;|</th>
			<th>RT TU %&nbsp;|</th>
			<th>latest RT timestamp&nbsp;|</th>
		    </tr>
		</thead>
		<tbody>
		    <GroupAgencyPerDayTableEntries array={array} />
		</tbody>
		</table>
            </>
	);
    }else{
	return (
            <>
		<p>loading...</p>
            </>
	);
    }
};
GroupAgencyPerDayTable.propTypes = {
    array: PropTypes.array,
    title: PropTypes.string,
    date: PropTypes.string,
};
