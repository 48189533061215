import React, { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../config';
import AgencyPerDayTable from '../components/agency-per-day-table';
import AgencySelect from '../components/agency-select';
import Input from '../components/input';

export default function AgencyPerDay() {

    const dateDefault = 'Select date';
    const [date, setDate] = useState(dateDefault);

    const agencyNameDefault = 'Select GTFS agency_name';

    /*store and initialize data in function component state*/
    const [strngAgencyId, setStrngAgencyId] = useState(agencyNameDefault);

    const [rryAgencyPerDay, setRryAgencyPerDay] = useState([]);
    const [rryAgencies, setRryAgencies] = useState([]);

    //TODO How do we handle invalid date input?
    const handleDate = (e) => {
	if (e.target.value.indexOf('2023') !== -1 ||
	   e.target.value.indexOf('2024') !== -1) {
            setDate((date)=>e.target.value);
	}
    };

    const getRryAgencies = async () => {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    const address = `${config.API}agencyids`;
	    //console.log('trip-updates-route-day res.data.length: address: ' + address);
            const res = await axios.get(address);
	    //console.log('trip-updates-route-day res.data.length: Agencies: ' + res.data.length);
            setRryAgencies((rryAgencies) => res.data);
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
    };

    const getRryAgencyPerDay = async () => {
	if ( strngAgencyId !== agencyNameDefault &&
	   date !== dateDefault) {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    const address = `${config.API}trip-updates-by-agency-day?agencyid=${strngAgencyId}&day=${date}`;
	    //console.log('trip-updates-route-day res.data.length: address: ' + address);
            const res = await axios.get(address);
	    if ( res.data !== undefined && res.data !== null ) {
		//console.log('trip-updates-route-day res.data.length: AgencyPerDay: ' + res.data.length);
		setRryAgencyPerDay((rryAgencyPerDay) => res.data);
	    } else {
		console.error('ERROR: trip-updates by routes and day request FAILED');
	    }
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	}
    };

    const handleChangeAgencyId = (event) => {
	//console.log('trip-updates-route-day: handleChangeAgencyId() value: ' + event.target.value);
        setStrngAgencyId((strngAgencyId) => event.target.value);
    };

    /*this hook is run after a DOM update. Changing state might result in an infinite loop*/
    /*hook need to be placed in body of the function component in which it is used*/
    useEffect(() => {
        getRryAgencies();
    /*use an empty dependency array to ensure the hook is running only once*/
    /*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, []);

    useEffect(() => {
	//console.log('trip-updates-route-day: useEffect() strngAgencyId: ' + strngAgencyId);
        getRryAgencyPerDay();
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [strngAgencyId, date]);


    return <>
                <label>
		    <Input
			id="inputDate"
			name={dateDefault}
			onChange={handleDate}
			placeholder="Enter date ${dateDefault}"
			type="date"
			title="Enter date ${dateDefault}"
			value={date}
		    />
		</label>
	       <AgencySelect rry={rryAgencies} name={agencyNameDefault} onChange={handleChangeAgencyId} />
	       <AgencyPerDayTable array={rryAgencyPerDay} title={'routes'} date={date}/>
	   </>;
};
