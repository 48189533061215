import React from 'react';

const CalendarDatesTableHead = () => {
    return (
        <tr>
            <th>service_id</th>
            <th>date</th>
            <th>exception_type</th>
        </tr>
    );
};

export default CalendarDatesTableHead;
