import React from 'react';

const TransfersTableHead = () => {
    return (
        <tr>
            <th>from_stop_id</th>
            <th>to_stop_id</th>
            <th>from_route_id</th>
            <th>to_route_id</th>
            <th>from_trip_id</th>
            <th>to_trip_id</th>
            <th>transfer_type</th>
            <th>min_transfer_time</th>
        </tr>
    );
};

export default TransfersTableHead;
