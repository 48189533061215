import React from 'react';
import PropTypes from 'prop-types';

/*controlled component: select controlled by React*/
export default function Select({defaultValue, id, name, onChange, options, title}) {
    if (options) {
        return <form >
			<label htmlFor="input-agency">{name}: </label>
			<select
			    name={name}
			    id={id}
			    className={name}
			    onChange={onChange}
			    placeholder={name}
			    defaultValue={defaultValue}
			    title={title}
			    type="text"
			    required
			>
			    {options.map((item, index) => (
				<option key={index} value={item}>
				    {item}
				</option>
			    ))}
			</select>
	       </form>;
    } else {
        return <p>Select options unavailable.</p>;
    }
};

Select.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.array,
    title: PropTypes.string
};
