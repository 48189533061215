import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Tables from '../components/tables';
import gtfs from '../utils/gtfs';

const Files = () => {
    return <fieldset>
               <legend><b>GTFS Schedule</b> file overview</legend>
	       <Tables data={gtfs.datasetFiles} />
           </fieldset>;
};
export default Files;
