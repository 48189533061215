import React from 'react'

export default function Header(){
    return <>
      	    <a href='/' rel="noopener noreferrer">
		<button>
		    Home
		</button>
	    </a>
      	    <a href='/agency' rel="noopener noreferrer">
		<button>
		    Agency
		</button>
	    </a>
      	    <a href='/files' rel="noopener noreferrer">
		<button>
		    Files
		</button>
	    </a>
      	    <a href='/realtime' rel="noopener noreferrer">
		<button>
		    Realtime
		</button>
	    </a>
      	    <a href='/contact' rel="noopener noreferrer">
		<button>
		    Contact
		</button>
	    </a>
      	    <a href='https://www.swingbe.de/imprint/'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Imprint
		</button>
	    </a>
      	    <a href='https://www.swingbe.de/privacy-policy/'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Privacy Policy
		</button>
	    </a>
      	    <a href='https://git.wtf-eg.de/dancingCycle/gtfs-display'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Source
		</button>
	    </a>
	   </>;
};
