import React from 'react';
import PropTypes from 'prop-types';

export default function FileSelect({ options, name, onChange, title }) {
    if (options.length > 0) {
        return <form >
		   <label htmlFor="input-agency">{name}: </label>
		   <select
		       name={name}
			    id={name}
			    className={name}
			    onChange={onChange}
			    placeholder={name}
			    defaultValue={name}
			    title={name}
			    type="text"
			    required
		   >
		       {options.map((item, index) => (
			   <option key={index} value={item}>
			       {item}
			   </option>
		       ))}
		   </select>
	       </form>;
    } else {
        return <p>Loading...</p>;
    }
};

FileSelect.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
    options: PropTypes.array
};
