import React from 'react';

const StopsTableHead = () => {
    return (
        <tr>
            <th>stop_id</th>
            <th>stop_code</th>
            <th>stop_name</th>
            <th>stop_desc</th>
            <th>stop_lat</th>
            <th>stop_lon</th>
            <th>location_type</th>
            <th>parent_station</th>
            <th>wheelchair_boarding</th>
            <th>platform_code</th>
            <th>zone_id</th>
        </tr>
    );
};

export default StopsTableHead;
