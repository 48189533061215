import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function RadioButton({ state, onChange }) {
    return (
    <div >
      <form>
        <fieldset>
            <legend>Select <b>GTFS Realtime</b> analysis rule</legend>
            <input
            type="radio"
            name="state"
            id='state-odd-routes'
            value="odd-routes"
            onChange={onChange}
            checked={state === 'odd-routes'} />
            <label htmlFor="state-odd-routes">
		&nbsp;Show <b>GTFS Realtime</b> entities <b>TripUpdate</b> with <b>odd routes</b>
	    </label><br />

            <input
            type="radio"
            name="state"
            id='state-odd-trips'
            value="odd-trips"
            onChange={onChange}
            checked={state === 'odd-trips'} />
            <label htmlFor="state-odd-trips">
		&nbsp;Show <b>GTFS Realtime</b> entities <b>TripUpdate</b> with <b>odd trips</b>
	    </label><br />

            <input
            type="radio"
            name="state"
            id='state-feed'
            value="feed"
            onChange={onChange}
            checked={state === 'feed'} />
            <label htmlFor="state-feed">
		&nbsp;Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>feed</b> level
	    </label><br />

            <input
            type="radio"
            name="state"
            id='state-agencies'
            value="agencies"
            onChange={onChange}
            checked={state === 'agencies'} />
            <label htmlFor="state-agencies">
		&nbsp;Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>agencies</b> level
	    </label><br />

            <input
            type="radio"
            name="state"
            id='state-routes'
            value="routes"
            onChange={onChange}
            checked={state === 'routes'} />
            <label htmlFor="state-routes">
		&nbsp;Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>routes</b> level
	    </label><br />

            <input
            type="radio"
            name="state"
            id='state-trips'
            value="trips"
            onChange={onChange}
            checked={state === 'trips'} />
            <label htmlFor="state-trips">
		&nbsp;Analyse <b>GTFS Realtime</b> entities <b>TripUpdate</b> on <b>trips</b> level
	    </label><br />
        </fieldset>
      </form>
    </div>
  );
};

RadioButton.propTypes = {
    state: PropTypes.string,
    onChange: PropTypes.func
};
