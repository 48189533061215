import React from 'react';
const AgencyTableHead = () => {
    return (
        <tr>
            <th>agency_id</th>
            <th>agency_name</th>
            <th>agency_url</th>
            <th>agency_timezone</th>
            <th>agency_lang</th>
            <th>agency_phone</th>
        </tr>
    );
};
export default AgencyTableHead;
